import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

export type AlertDialogIcon = 'none' | 'success' | 'error' | 'warn' | 'info' | 'help';

const initialState = {
  open: false,
  icon: 'none' as AlertDialogIcon,
  title: '',
  description: '',
  okText: 'Done',
  submitting: false,
  submittingText: 'Submitting...',
  className: '',
  closeOnDone: true,
  okCallback: () => {},
  closeCallback: () => {},
};

export const alertDialogManager = proxy(initialState);

devtools(alertDialogManager, {
  name: 'Global Alert Dialog',
  enabled: false,
});

export const openAlertDialog = (payload: Partial<Omit<typeof initialState, 'open'>>) => {
  Object.assign(alertDialogManager, initialState, {
    open: true,
    ...payload,
  });
};

export const closeAlertDialog = () => {
  Object.assign(alertDialogManager, initialState);
};

export const toggleSubmitting = (value: boolean) => {
  const { open } = alertDialogManager;
  if (!open) return;
  alertDialogManager.submitting = value;
};
