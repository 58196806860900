'use client';

import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import {
  AlertCircleIcon,
  CheckCircleIcon,
  HelpCircleIcon,
  InfoIcon,
  XCircleIcon,
  XIcon,
} from 'lucide-react';
import { cloneElement, forwardRef, isValidElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';

import { Button } from '../button';
import { closeConfirmDialog, confirmDialogManager } from './model';

const alertDialogIconMap = {
  none: null,
  success: <CheckCircleIcon className="text-green" />,
  error: <XCircleIcon className="text-pink" />,
  warn: <AlertCircleIcon className="text-amber" />,
  info: <InfoIcon className="text-blue" />,
  help: <HelpCircleIcon className="text-blue" />,
};

export const ConfirmDialogRoot = AlertDialogPrimitive.Root;
export const ConfirmDialogTrigger = AlertDialogPrimitive.Trigger;
export const ConfirmDialogPortal = AlertDialogPrimitive.Portal;
export const ConfirmDialogTitle = AlertDialogPrimitive.Title;
export const ConfirmDialogDescription = AlertDialogPrimitive.Description;
export const ConfirmDialogAction = AlertDialogPrimitive.Action;
export const ConfirmDialogCancel = AlertDialogPrimitive.Cancel;

export const ConfirmDialogClose = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ hidden, className, ...restProps }, ref) => {
  if (hidden) {
    return null;
  }

  return (
    <ConfirmDialogCancel asChild>
      <button
        ref={ref}
        type="button"
        className={twMerge(
          'absolute right-[20px] top-[20px] flex size-8 cursor-pointer items-center justify-center rounded bg-transparent text-grey-dark transition-colors duration-150 ease-linear hover:bg-grey-light hover:text-blue sm:right-[30px] sm:top-[30px]',
          className,
        )}
        {...restProps}
      >
        <XIcon className="size-5 text-current" />
      </button>
    </ConfirmDialogCancel>
  );
});

export const ConfirmDialogOverlay = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...restProps }, ref) => {
  return (
    <AlertDialogPrimitive.Overlay
      ref={ref}
      className={twMerge('fixed inset-0 z-[500] bg-black/50', className)}
      {...restProps}
    />
  );
});

export const ConfirmDialogContent = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>
>(({ className, ...restProps }, ref) => {
  return (
    <AlertDialogPrimitive.Content
      ref={ref}
      className={twMerge(
        'fixed left-1/2 top-1/2 z-[501] max-h-[85vh] w-[90vw] max-w-[500px] -translate-x-1/2 -translate-y-1/2 rounded-2xl rounded-tl-none bg-white px-5 py-10 shadow-lg focus:outline-none sm:px-[30px]',
        className,
      )}
      {...restProps}
    />
  );
});

export const GlobalConfirmDialog = forwardRef(() => {
  const {
    open,
    icon: iconName,
    title,
    description,
    center,
    danger,
    confirmText,
    cancelText,
    confirming,
    confirmingText,
    cancelling,
    cancellingText,
    closeOnConfirm,
    closeOnCancel,
    showCloseIcon,
    className,
    confirmCallback,
    cancelCallback,
    closeCallback,
  } = useSnapshot(confirmDialogManager);
  const icon = alertDialogIconMap[iconName];

  const handleDialogClose = () => {
    closeConfirmDialog();
    setTimeout(closeCallback, 150);
  };

  return (
    <ConfirmDialogRoot open={open} onOpenChange={handleDialogClose}>
      <ConfirmDialogPortal>
        <ConfirmDialogOverlay />
        <ConfirmDialogContent className={twMerge(center ? 'text-center' : 'text-left', className)}>
          <ConfirmDialogClose hidden={!showCloseIcon} />
          <div className="space-y-9">
            <header className="space-y-6">
              {isValidElement<React.SVGAttributes<SVGElement>>(icon)
                ? cloneElement(icon, {
                    ...icon.props,
                    'aria-hidden': 'true',
                    'className': twMerge('mx-auto size-6', icon.props.className),
                  })
                : null}
              <div className="space-y-3.5">
                {title ? (
                  <ConfirmDialogTitle className="pr-8 text-xl font-semibold">
                    {title}
                  </ConfirmDialogTitle>
                ) : null}
                {description ? (
                  <ConfirmDialogDescription
                    className="text-base"
                    dangerouslySetInnerHTML={{
                      __html: description.replaceAll(
                        /\*{2}(\S[^*]*\S)\*{2}/gm,
                        '<strong>$1</strong>',
                      ),
                    }}
                  />
                ) : null}
              </div>
            </header>
            <footer
              className={twMerge(
                'flex w-full flex-wrap items-center gap-x-2.5',
                center ? 'justify-center' : 'justify-end',
              )}
            >
              {closeOnCancel ? (
                <ConfirmDialogAction asChild>
                  <Button
                    full={false}
                    variant="subtle"
                    loading={cancelling}
                    onClick={cancelCallback}
                  >
                    {cancelling ? cancellingText : cancelText}
                  </Button>
                </ConfirmDialogAction>
              ) : (
                <Button full={false} variant="subtle" loading={cancelling} onClick={cancelCallback}>
                  {cancelling ? cancellingText : cancelText}
                </Button>
              )}
              {closeOnConfirm ? (
                <ConfirmDialogAction asChild>
                  <Button
                    full={false}
                    variant="solid"
                    danger={danger}
                    loading={confirming}
                    onClick={confirmCallback}
                  >
                    {confirming ? confirmingText : confirmText}
                  </Button>
                </ConfirmDialogAction>
              ) : (
                <Button
                  full={false}
                  variant="solid"
                  danger={danger}
                  loading={confirming}
                  onClick={confirmCallback}
                >
                  {confirming ? confirmingText : confirmText}
                </Button>
              )}
            </footer>
          </div>
        </ConfirmDialogContent>
      </ConfirmDialogPortal>
    </ConfirmDialogRoot>
  );
});

ConfirmDialogClose.displayName = 'ConfirmDialogClose';
ConfirmDialogOverlay.displayName = 'ConfirmDialogOverlay';
ConfirmDialogContent.displayName = 'ConfirmDialogContent';
GlobalConfirmDialog.displayName = 'GlobalConfirmDialog';
