'use client';

import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import {
  AlertCircleIcon,
  CheckCircleIcon,
  HelpCircleIcon,
  InfoIcon,
  XCircleIcon,
} from 'lucide-react';
import { cloneElement, forwardRef, isValidElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';

import { Button } from '../button';
import { alertDialogManager, closeAlertDialog } from './model';

const alertDialogIconMap = {
  none: null,
  success: <CheckCircleIcon className="text-green" />,
  error: <XCircleIcon className="text-pink" />,
  warn: <AlertCircleIcon className="text-amber" />,
  info: <InfoIcon className="text-blue" />,
  help: <HelpCircleIcon className="text-blue" />,
};

export const AlertDialogRoot = AlertDialogPrimitive.Root;
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
export const AlertDialogPortal = AlertDialogPrimitive.Portal;
export const AlertDialogTitle = AlertDialogPrimitive.Title;
export const AlertDialogDescription = AlertDialogPrimitive.Description;
export const AlertDialogAction = AlertDialogPrimitive.Action;
export const AlertDialogCancel = AlertDialogPrimitive.Cancel;

export const AlertDialogOverlay = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...restProps }, ref) => {
  return (
    <AlertDialogPrimitive.Overlay
      ref={ref}
      className={twMerge('fixed inset-0 z-[500] bg-black/50', className)}
      {...restProps}
    />
  );
});

export const AlertDialogContent = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>
>(({ className, ...restProps }, ref) => {
  return (
    <AlertDialogPrimitive.Content
      ref={ref}
      className={twMerge(
        'fixed left-1/2 top-1/2 z-[501] max-h-[85vh] w-[90vw] max-w-[400px] -translate-x-1/2 -translate-y-1/2 space-y-9 rounded-2xl rounded-tl-none bg-white px-5 py-10 text-center shadow-lg focus:outline-none sm:px-[30px]',
        className,
      )}
      {...restProps}
    />
  );
});

export const GlobalAlertDialog = forwardRef(() => {
  const {
    open,
    icon: iconName,
    title,
    description,
    okText,
    submitting,
    submittingText,
    closeOnDone,
    className,
    okCallback,
    closeCallback,
  } = useSnapshot(alertDialogManager);
  const icon = alertDialogIconMap[iconName];

  const handleDialogClose = () => {
    closeAlertDialog();
    setTimeout(closeCallback, 150);
  };

  return (
    <AlertDialogRoot open={open} onOpenChange={handleDialogClose}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent className={className}>
          <div className="space-y-6">
            {isValidElement<React.SVGAttributes<SVGElement>>(icon)
              ? cloneElement(icon, {
                  ...icon.props,
                  'aria-hidden': 'true',
                  'className': twMerge('mx-auto size-6', icon.props.className),
                })
              : null}
            <div className="space-y-3.5">
              {title ? (
                <AlertDialogTitle className="text-xl font-semibold">{title}</AlertDialogTitle>
              ) : null}
              {description ? (
                <AlertDialogDescription
                  className="text-base"
                  dangerouslySetInnerHTML={{
                    __html: description.replaceAll(
                      /\*{2}(\S[^*]*\S)\*{2}/gm,
                      '<strong>$1</strong>',
                    ),
                  }}
                />
              ) : null}
            </div>
          </div>
          {closeOnDone ? (
            <AlertDialogAction asChild>
              <Button full={false} variant="solid" loading={submitting} onClick={okCallback}>
                {submitting ? submittingText : okText}
              </Button>
            </AlertDialogAction>
          ) : (
            <Button full={false} variant="solid" loading={submitting} onClick={okCallback}>
              {submitting ? submittingText : okText}
            </Button>
          )}
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialogRoot>
  );
});

AlertDialogOverlay.displayName = 'AlertDialogOverlay';
AlertDialogContent.displayName = 'AlertDialogContent';
GlobalAlertDialog.displayName = 'GlobalAlertDialog';
