'use client';

import { Toaster } from 'sonner';

import { GlobalAlertDialog } from '../alert-dialog';
import { GlobalConfirmDialog } from '../confirm-dialog';
import { GlobalDialog } from '../dialog/GlobalDialog';
import { TooltipProvider } from '../tooltip';

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <TooltipProvider>
      <Toaster position="top-right" richColors />
      <GlobalAlertDialog />
      <GlobalConfirmDialog />
      <GlobalDialog />
      {children}
    </TooltipProvider>
  );
};
