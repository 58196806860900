'use client';

import { cva, type VariantProps } from 'class-variance-authority';
import Link from 'next/link';
import { forwardRef, useContext } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonGroupContext } from '../button-group';
import { Spinner } from '../spinner';

const buttonVariants = cva(
  'relative inline-flex cursor-pointer select-none appearance-none items-center justify-center border border-solid border-transparent font-medium no-underline outline-none transition-all duration-150 ease-linear focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue/50 active:scale-[0.97] disabled:cursor-not-allowed disabled:shadow-none disabled:transition-none disabled:active:transform-none [&>svg]:shrink-0',
  {
    variants: {
      size: {
        xs: 'h-[30px] min-w-[30px] gap-x-2.5 rounded px-[14px] text-xs',
        sm: 'h-[36px] min-w-[36px] gap-x-3 rounded px-[18px] text-sm',
        md: 'h-[42px] min-w-[42px] gap-x-[14px] rounded-md px-[22px] text-base',
        lg: 'h-[50px] min-w-[50px] gap-x-4 rounded-lg px-[26px] text-lg',
        xl: 'h-[60px] min-w-[60px] gap-x-5 rounded-lg px-[32px] text-xl',
      },
      full: {
        true: 'flex w-full',
      },
      variant: {
        default:
          'border-grey-mid bg-white text-black shadow-sm hover:bg-grey-light active:bg-grey-light disabled:bg-grey-light disabled:text-grey-tertiary',
        solid:
          'bg-blue text-white shadow-sm hover:bg-blue-dark active:bg-blue-dark disabled:bg-grey-tertiary disabled:text-white',
        outline:
          'border-blue bg-transparent text-blue hover:border-blue-dark hover:bg-tint-light hover:text-blue-dark active:border-blue-dark active:bg-tint-light active:text-blue-dark disabled:border-grey-mid disabled:bg-grey-light disabled:text-grey-tertiary',
        light:
          'bg-grey-light text-blue hover:bg-tint-light hover:text-blue-dark active:bg-tint-light active:text-blue-dark disabled:bg-grey-light disabled:text-grey-tertiary',
        subtle:
          'bg-transparent text-blue hover:bg-tint-light hover:text-blue-dark active:bg-tint-light active:text-blue-dark disabled:bg-transparent disabled:text-grey-tertiary',
        link: 'bg-transparent text-blue underline-offset-4 hover:text-blue-dark hover:underline active:text-blue-dark active:underline disabled:text-grey-tertiary disabled:no-underline',
      },
      danger: {
        true: '',
      },
      disabled: {
        true: 'cursor-not-allowed shadow-none transition-none active:transform-none',
      },
      loading: {
        true: '',
      },
    },
    compoundVariants: [
      {
        variant: 'default',
        danger: true,
        className: 'text-pink hover:text-pink-dark active:text-pink-dark',
      },
      {
        variant: 'solid',
        danger: true,
        className: 'bg-pink hover:bg-pink-dark active:bg-pink-dark',
      },
      {
        variant: 'outline',
        danger: true,
        className:
          'border-pink text-pink hover:border-pink-dark hover:bg-pink-light hover:text-pink-dark active:border-pink-dark active:bg-pink-light active:text-pink-dark',
      },
      {
        variant: 'light',
        danger: true,
        className:
          'bg-grey-light text-pink hover:bg-pink-light hover:text-pink-dark active:bg-pink-light active:text-pink-dark',
      },
      {
        variant: 'subtle',
        danger: true,
        className:
          'text-pink hover:bg-pink-light hover:text-pink-dark active:bg-pink-light active:text-pink-dark',
      },
      {
        variant: 'link',
        danger: true,
        className: 'text-pink hover:text-pink-dark active:text-pink-dark',
      },
      {
        variant: 'default',
        disabled: true,
        className: 'bg-grey-light text-grey-tertiary',
      },
      {
        variant: 'solid',
        disabled: true,
        className:
          'bg-grey-tertiary text-white hover:bg-grey-tertiary hover:text-white active:bg-grey-tertiary active:text-white',
      },
      {
        variant: 'outline',
        disabled: true,
        className:
          'border-grey-mid bg-grey-light text-grey-tertiary hover:border-grey-mid hover:bg-grey-light hover:text-grey-tertiary active:border-grey-mid active:bg-grey-light active:text-grey-tertiary',
      },
      {
        variant: 'light',
        disabled: true,
        className:
          'bg-grey-light text-grey-tertiary hover:bg-grey-light hover:text-grey-tertiary active:bg-grey-light active:text-grey-tertiary',
      },
      {
        variant: 'subtle',
        disabled: true,
        className:
          'bg-transparent text-grey-tertiary hover:bg-transparent hover:text-grey-tertiary active:bg-transparent active:text-grey-tertiary',
      },
      {
        variant: 'link',
        disabled: true,
        className:
          'text-grey-tertiary no-underline hover:text-grey-tertiary hover:no-underline active:text-grey-tertiary active:no-underline',
      },
    ],
    defaultVariants: {
      size: 'md',
      full: true,
      variant: 'default',
    },
  },
);

type ButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorAttributes = React.ComponentPropsWithRef<typeof Link>;

export interface NativeButtonProps
  extends VariantProps<typeof buttonVariants>,
    Omit<ButtonAttributes, 'disabled' | 'size'> {
  as?: 'button';
  spinner?: React.ReactNode;
}

export interface LinkButtonProps
  extends VariantProps<typeof buttonVariants>,
    Omit<AnchorAttributes, 'size'> {
  as: 'a';
  spinner?: React.ReactNode;
}

export type ButtonProps = NativeButtonProps | LinkButtonProps;

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      as = 'button',

      size,
      full,
      variant,
      danger,
      disabled: _disabled,
      loading,
      spinner = <Spinner />,

      hidden,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const buttonGroup = useContext(ButtonGroupContext);
    const disabled = Boolean(buttonGroup.disabled || _disabled || loading);

    if (hidden) {
      return null;
    }

    const mergedClassName = twMerge(
      buttonVariants({
        size: size ?? buttonGroup.size,
        full: full ?? buttonGroup.full,
        variant: variant ?? buttonGroup.variant,
        danger,
        disabled: as === 'button' ? null : disabled,
        loading,
        className,
      }),
    );

    const dom =
      loading && spinner ? (
        <>
          {spinner}
          <span>{children}</span>
        </>
      ) : (
        children
      );

    if (as === 'a') {
      const { href, ...restProps } = props as AnchorAttributes;

      if (disabled || !href) {
        return (
          <a
            ref={ref as React.Ref<HTMLAnchorElement>}
            aria-disabled="true"
            aria-invalid="true"
            className={mergedClassName}
            {...restProps}
          >
            {dom}
          </a>
        );
      }

      return (
        <Link
          ref={ref as React.Ref<HTMLAnchorElement>}
          href={href}
          className={mergedClassName}
          {...restProps}
        >
          {dom}
        </Link>
      );
    }

    const { type = 'button', ...restProps } = props as ButtonAttributes;

    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        type={type}
        disabled={disabled}
        aria-disabled={disabled ? 'true' : undefined}
        className={mergedClassName}
        {...restProps}
      >
        {dom}
      </button>
    );
  },
);

Button.displayName = 'Button';
