import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

export type ConfirmDialogIcon = 'none' | 'success' | 'error' | 'warn' | 'info' | 'help';

const initialState = {
  open: false,
  icon: 'none' as ConfirmDialogIcon,
  title: '',
  description: '',
  center: false,
  danger: false,
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  cancelling: false,
  cancellingText: 'Cancelling...',
  confirming: false,
  confirmingText: 'Confirming...',
  className: '',
  closeOnConfirm: true,
  closeOnCancel: true,
  showCloseIcon: false,
  confirmCallback: () => {},
  cancelCallback: () => {},
  closeCallback: () => {},
};

export const confirmDialogManager = proxy(initialState);

devtools(confirmDialogManager, {
  name: 'Global Confirm Dialog',
  enabled: false,
});

export const openConfirmDialog = (payload: Partial<Omit<typeof initialState, 'open'>>) => {
  Object.assign(confirmDialogManager, initialState, {
    open: true,
    ...payload,
  });
};

export const closeConfirmDialog = () => {
  Object.assign(confirmDialogManager, initialState);
};

export const toggleConfirming = (value: boolean) => {
  const { open } = confirmDialogManager;
  if (!open) return;
  confirmDialogManager.confirming = value;
};

export const toggleCancelling = (value: boolean) => {
  const { open } = confirmDialogManager;
  if (!open) return;
  confirmDialogManager.cancelling = value;
};
